//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import i18n from '@/vueI18n';

const { fields } = UserModel;

export default {
  name: 'app-forgot-password-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
      },
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loadingPasswordResetEmail',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language(){
      return i18n.locale
    },

    fields() {
      return fields;
    },
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.forgotPasswordTitle') 
    },
  },

  methods: {
    ...mapActions({
      doSendPasswordResetEmail:
        'auth/doSendPasswordResetEmail',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      await this.doSendPasswordResetEmail(this.model.email);
    },
  },
};
